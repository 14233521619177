import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ILogin,ISetPassword } from '../../shared/model/login/login.model';
import { HttpStatusCode, ResponseModel } from '../../shared/responsemodel';
import { ApiDataService } from '../../shared/services/apidata.service';
import { environment } from '../../../environments/environment';
import { ToastService } from '../../shared/services/toast.service';
import { Messagetype } from '../../shared/model/master/enum';


@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loginwithotpForm: FormGroup;
  forgetPasswordForm: FormGroup;
  submitted = false;
  isSaveDisabled = false;
  loading: boolean = false;
  isLive = true;
  info: any;
  isLaunch = false;

  pageRoleList: any[];

  public data: any;

  username: string;

  loginfailerror = false;
  loginOtpfailerror = false;
  otpfailerror = false;
  invalidUsernameError = false;
  showloder = false;
  showform = 'login';
  inputType = 'password';

  showloderforotp = false;
  showLoaderForVerify = false;
  showLoaderForSetPassword = false;

  setpasswordDisabled = true;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private apiservice: ApiDataService,
    private toastService: ToastService,

  ) {

    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    this.loginwithotpForm = this.fb.group({
      otp: ['', [Validators.required]],
    });

    this.forgetPasswordForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      otp: ['', [Validators.required]],
    });
  }

  ngOnInit() {

    // if (localStorage.getItem('info')) {
    //   this.router.navigateByUrl('/dashboard');
    // }
    if (localStorage.getItem('info')) {
			localStorage.removeItem('info');
		}
		if (localStorage.getItem('roleInfo')) {
			localStorage.removeItem('roleInfo');
		}

  }
  get f() {
    return this.loginForm.controls;
  }
  get fff() {
		return this.forgetPasswordForm.controls;
	}
	public pageRole() {
		const url = `PageAccess/GetPageAccessRolewise?pageSize=0&pageNumber=0`;
		this.apiservice.getData(url).subscribe
			((response: ResponseModel<any[]>) => {
				if (response.status === HttpStatusCode.OK) {
					this.pageRoleList = response.data;
					localStorage.setItem('roleInfo', JSON.stringify(response.data));
          setTimeout(()=>{                       
            this.router.navigateByUrl('/dashboard');
          }, 10000);
				}
			});
	}



  public login() {

    //  this.router.navigateByUrl('/dashboard');

    this.submitted = true;
    this.loginfailerror = false;
    this.loginOtpfailerror = false;

    this.loginForm = this.fb.group({
      username: [this.loginForm.value.username, [Validators.required]],
      password: [this.loginForm.value.password, [Validators.required]],
    });

    if (this.loginForm.valid) {
      this.isSaveDisabled = true;

      const data: ILogin = {
        userName: this.loginForm.value.username,
        password: this.loginForm.value.password,
        loginfrom: 1,
      };
      this.showloder = true;
      const url = `AdminUser/login`;
      this.apiservice
        .postData(url, data)
        .subscribe((response: ResponseModel<any>) => {
          this.showloder = false;
          if (response.status === HttpStatusCode.OK) {
            this.data = response.data;
            this.isSaveDisabled = false;

            this.loginfailerror = false;
            localStorage.setItem('info', JSON.stringify(response.data));

            this.pageRole();
            this.router.navigateByUrl('/dashboard');
          } else {
            this.loginfailerror = true;
            this.isSaveDisabled = false;
          }
        });
    }
  }


  public sendOTP() {
		this.forgetPasswordForm = this.fb.group({
			username: [this.forgetPasswordForm.value.username, [Validators.required]],
			password: [this.forgetPasswordForm.value.password],
			otp: [this.forgetPasswordForm.value.otp],
		});

		this.submitted = true;

		this.otpfailerror = false;
		this.invalidUsernameError = false;

		if (this.forgetPasswordForm.valid) {
			this.showloder = true;
      // const url = `AdminUser/forgetPassword?username=${this.forgetPasswordForm.value.username}`;
      const url = `${environment.apiurl}AdminUser/forgetPassword?username=${this.forgetPasswordForm.value.username}`;
			this.apiservice
				.getOTP(url)
				.subscribe((response: ResponseModel<any>) => {
					this.showloder = false;
					if (response.status === HttpStatusCode.OK) {
						// localStorage.setItem('info', JSON.stringify(response.data));
						// this.router.navigateByUrl('/dashboard/akdashboard');
            this.toastService.showToast(
              Messagetype.sucess,
              'Info',
              `${response.statusMessage}`
            );
						this.setpasswordDisabled = false;
					} else {
						this.setpasswordDisabled = true;
						this.invalidUsernameError = true;
					}
				});
		}
	}

  public setPasswordlogin() {
		this.forgetPasswordForm = this.fb.group({
			username: [this.forgetPasswordForm.value.username, [Validators.required]],
			password: [this.forgetPasswordForm.value.password, [Validators.required]],
			otp: [this.forgetPasswordForm.value.otp, [Validators.required]],
		});

		this.submitted = true;
		this.loginOtpfailerror = false;

		if (this.forgetPasswordForm.valid) {
			this.showLoaderForSetPassword = true;

			const data1: ISetPassword = {
				username: this.forgetPasswordForm.value.username,
				otp: this.forgetPasswordForm.value.otp,
				loginfrom: 1,
				newPassword: this.forgetPasswordForm.value.password,
			};

      const url = `AdminUser/setpassLogin`;
			this.apiservice
				.postData(url, data1)
				.subscribe((response: ResponseModel<any>) => {
					this.showLoaderForSetPassword = false;
					if (response.status === HttpStatusCode.OK) {
						localStorage.setItem('info', JSON.stringify(response.data));
            this.pageRole();
            this.router.navigateByUrl('/dashboard');
					} else {
						this.loginOtpfailerror = true;
					}
				});
		}
	}

  backclick(form) {
		this.submitted = false;
		this.showform = form;
		this.forgetPasswordForm.reset();
		this.loginForm.reset();
		this.loginwithotpForm.reset();
	}
}
