

import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Messagetype } from '../model/master/enum';




@Injectable({
  providedIn: 'root'
})
export class ToastService {


  config: any = {
    closeButton: true,
    positionClass: 'toast-bottom-center'
  }

  constructor(
    private toastrService: ToastrService
  ) { }

  showToast(reason: Messagetype, title: string, message: string) {
    if (reason === Messagetype.sucess) {
      this.toastrService.success(message, title, this.config);
    }
    if (reason === Messagetype.fail) {
      this.toastrService.warning(message, title, this.config);
    }
  }


}
