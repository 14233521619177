import { Injectable } from '@angular/core';
import { INavData } from '@coreui/angular';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true;

  constructor() { }

  public setMenu(): INavData[] {

    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);

    const MENU_ITEMS: INavData[] = [];
    const Dashboard: INavData = {
      name: 'Dashboard',
      icon: 'icon-speedometer',
      url: '/dashboard',
        };

    const Master: INavData = {
      name: 'Masters',
      icon: 'icon-list',
      children: []
    };

    const User: INavData = {
      name: 'User',
      icon: 'icon-user',
      children: []
    };

    const Inventory: INavData = {
      name: 'Inventory',
      icon: 'icon-puzzle',
      children: []
    };

    const Orders: INavData = {
      name: 'Orders',
      icon: ' icon-badge',
      children: []
    };
    const Reports: INavData = {
      name: 'Reports',
      icon: 'layout-outline',
      url: '/master/report'
    };

    const OtherList: INavData = {
      name: 'Other List',
      icon: 'layout-outline',
      children: []
    };

    const ReleasePayment: INavData = {
      name: 'Release Payment',
      icon: 'layout-outline',
      children: []
    };


    const Blogs: INavData = {
      name: 'Blogs',
      icon: 'layout-outline',
      children: []
    };

    const Donations: INavData = {
      name: 'Donations',
      icon: 'layout-outline',
      children: []
    };


    MENU_ITEMS.push(Dashboard); // 0
    MENU_ITEMS.push(Master); // 1
    MENU_ITEMS.push(User); // 2
    MENU_ITEMS.push(Inventory); // 3
    MENU_ITEMS.push(Orders); // 4
    MENU_ITEMS.push(Reports); // 5
    // MENU_ITEMS.push(OtherList); // 6
    // MENU_ITEMS.push(ReleasePayment);  // 7
    // MENU_ITEMS.push(Blogs); // 8
    // MENU_ITEMS.push(Donations); // 9


    if (role.page) {
      role.page.forEach(item => {
        // Master
        if (item.page === 'Appoinment' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Appointment',
              url: '/user/appointment',
              icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'Charactristics' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Characteristics',
              url: '/master/characteristics',
              icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'Category' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Category',
              url: '/master/category',
              icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'Subcategory' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Subcategory',
              url: '/master/subcategory',
              icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'HomeRemedes' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Home remedies',
              url: '/master/disease',
              icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'Product' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Product',
              url: '/master/product',
              icon: 'icon-cursor',
            }
          );
        }
        if (item.page === 'OurEvent' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Our event',
              url: '/master/gallary',
              icon: 'icon-cursor',
            }
          );
        }
        if (item.page === 'Branch' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Branch',
              url: '/master/branch',
              icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'CaseStudy' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Case study',
              url: '/master/casestudy',
              icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'SuccessStories' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Success stories',
              url: '/master/sucessstories',
              icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'Successdisease' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Success disease',
              url: '/master/success_disease',
              icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'Testimonial' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Testimonials',
              url: '/master/testimonial',
              icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'Purpose' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Purpose',
              url: '/master/purpose',
              icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'Team' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Team',
              url: '/master/team',
              icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'Achivement' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Achievement',
              url: '/master/achievement',
              icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'Banner' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Banner',
              url: '/master/banner',
              icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'RoleRight' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Role Right',
              url: '/master/rights',
              icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'BusinessValueFactor' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              name: 'Business value factor',
              url: '/master/valuefactor',
              icon: 'icon-cursor'
            }
          );
        }



        // if (item.page === 'Role' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[1].children.push(
        //     {
        //       name: 'Role',
        //       url: '/masters/role',
        //     }
        //   );
        // }

        //   name: 'Report',
        //   url: '/master/report',
        //   icon: 'icon-cursor'

        // if (item.page === 'warehouse' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[1].children.push(
        //     {
        //       name: 'Warehouse',
        //       url: '/stock/warehouse',
        //     }
        //   );
        // }

        // User ------------------------------------------------------------------

        if (item.page === 'Register-User' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[2].children.push(
            {
              name: 'Register-user',
              url: '/master/register-user',
              icon: 'icon-cursor',
            }
          );
        }
        if (item.page === 'Employee' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[2].children.push(
            {
              name: 'Employee',
              url: '/master/employee',
              icon: 'icon-cursor'
            }
          );
        }

        // Inventory  ----------------------------------------
        if (item.page === 'Stock-In' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[3].children.push(
            {
              name: 'Stock-in',
              url: '/inventory/stock in',
              icon: 'icon-cursor',
            }
          );
        }

        if (item.page === 'Vendor' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[3].children.push(
            {
              name: 'Vendor',
              url: '/master/vendor',
              icon: 'icon-cursor',
            }
          );
        }
        if (item.page === 'Warehouse' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[3].children.push({
            name: 'Warehouse',
            url: '/master/warehouse',
            icon: 'icon-cursor',
          });
        }
        if (item.page === 'CompanyStock' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[3].children.push({
            name:'Company-Stock',
            url:'/inventory/company_stock',
            icon: 'icon-cursor',
          });
        }
        if (item.page === 'WarehouseStock' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[3].children.push({
            name:'Warehouse-Stock',
            url:'/inventory/warehouse_stock',
            icon: 'icon-cursor',
          });
        }

        // Orders
        if (item.page === 'New' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[4].children.push(
            {
              name: 'New',
              url: '/dashboard/initial',
              icon: 'icon-cursor',
            }
          );
        }
        if (item.page === 'Accept' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[4].children.push(
            {
              name: 'Accept',
              url: '/dashboard/order-accept',
              icon: 'icon-cursor',
            }
          );
        }

        if (item.page === 'In-Process' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[4].children.push(
            {
              name: 'In-process',
              url: '/dashboard/in-process',
              icon: 'icon-cursor',
            }
          );
        }
        if (item.page === 'Delivered' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[4].children.push(
            {
              name: 'Delivered',
              url: '/dashboard/delivered',
              icon: 'icon-cursor',
            }
          );
        }
        if (item.page === 'Cancelled' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[4].children.push(
            {
              name: 'Cancelled',
              url: '/dashboard/cancel',
              icon: 'icon-cursor',
            }
          );
        }



        // Release Payment    ----------------------------------------------------------------
        // if (item.page === 'Business partnerPayment' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[5].children.push(
        //     {
        //       name: 'Business Partner',
        //       url: '/payment/rel-bp-payment',
        //     }
        //   );
        // }
        // if (item.page === 'Delivery boyPayment' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[5].children.push(
        //     {
        //       name: 'Delivery  Boy',
        //       url: '/payment/rel-db-payment',
        //     }
        //   );
        // }

        // if (item.page === 'cod' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[5].children.push(
        //     {
        //       name: 'Delivery Boy Collection',
        //       url: '/stock/cod',
        //     }
        //   );
        // }
        // Other List -------------------------------------------------------

        // if (item.page === 'Recipe' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[6].children.push(
        //     {
        //       name: 'Recipe',
        //       url: '/masters/recipe',
        //     }
        //   );
        // }
        // if (item.page === 'Rating' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[6].children.push(
        //     {
        //       name: 'Rating',
        //       url: '/masters/rating',
        //     }
        //   );
        // }
        // if (item.page === 'Review' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[6].children.push(
        //     {
        //       name: 'Review',
        //       url: '/masters/review',
        //     }
        //   );
        // }
        // if (item.page === 'Help & support' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[6].children.push(
        //     {
        //       name: 'Help & Support',
        //       url: '/masters/help',
        //     }
        //   );
        // }

        // Blogs -------------------------------------------------------

        // if (item.page === 'blog' && (item.isRead || item.isWrite)) {
        // if (item.page === 'blog' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[8].children.push(
        //     {
        //       name: 'Blog',
        //       url: '/blogs/blog',
        //     }
        //   );
        // }
        // if (item.page === 'comment' && (item.isRead || item.isWrite)) {
        // if (item.page === 'comment' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[8].children.push(
        //     {
        //       name: 'Comments',
        //       url: '/blogs/comments',
        //     }
        //   );
        // }

        // Donations-------------------------------------------------------

        // if (item.page === 'donations' && (item.isRead || item.isWrite)) {
        // if (item.page === 'donor' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[9].children.push(
        //     {
        //       name: 'Donor',
        //       url: '/donations/donor',
        //     }
        //   );
        // }
        // if (item.page === 'donation' && (item.isRead || item.isWrite)) {
        // if (item.page === 'donororder' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[9].children.push(
        //     {
        //       name: 'Donor order',
        //       url: '/donations/donor_order',
        //     }
        //   );
        // }
        // if (item.page === 'donation' && (item.isRead || item.isWrite)) {
        // if (item.page === 'canteen' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[9].children.push(
        //     {
        //       name: 'Canteen',
        //       url: '/donations/canteen',
        //     }
        //   );
        // }



      });

      // remove unnesessary items To DO


      //MENU_ITEMS.push(Dashboard); // 0



      // MENU_ITEMS.push(OtherList); // 6
      // const otherListResult = role.page.find(repo =>
      //   (repo.page === 'Recipe' && (repo.isRead || repo.isWrite)) ||
      //   (repo.page === 'Rating' && (repo.isRead || repo.isWrite)) ||
      //   (repo.page === 'Review' && (repo.isRead || repo.isWrite)) ||
      //   (repo.page === 'Help & support' && (repo.isRead || repo.isWrite))
      // );

      // if (!otherListResult) {
      //   MENU_ITEMS.splice(6, 1);
      // }

      // MENU_ITEMS.push(Reports); // 7

      // const releasePaymentResult = role.page.find(repo =>
      //   (repo.page === 'Business partner' && (repo.isRead || repo.isWrite)) ||
      //   (repo.page === 'Delivery boy' && (repo.isRead || repo.isWrite))
      // );

      // if (!releasePaymentResult) {
      //   MENU_ITEMS.splice(5, 1);
      // }

      // check for reports   5

      const reportResult = role.page.find(repo =>

        (repo.page === 'UserReport' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'CompletedOrderReport' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'StockReport' && (repo.isRead || repo.isWrite))
      );

      if (!reportResult) {
        MENU_ITEMS.splice(5, 1);
      }

      //  MENU_ITEMS.push(Orders); // 4
      const orderResult = role.page.find(repo =>
        (repo.page === 'New' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Accept' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'In-Process' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Delivered' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Cancelled' && (repo.isRead || repo.isWrite))
      );

      if (!orderResult) {
        MENU_ITEMS.splice(4, 1);
      }

      // MENU_ITEMS.push(Inventory); // 3

      const inventoryResult = role.page.find(repo =>
        (repo.page === 'Stock-In' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Vendor' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Warehouse' && (repo.isRead || repo.isWrite))
      );

      if (!inventoryResult) {
        MENU_ITEMS.splice(3, 1);
      }

      // MENU_ITEMS.push(User); // 2


      const userResult = role.page.find(repo =>
        (repo.page === 'Register-User' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Employee' && (repo.isRead || repo.isWrite))
      );

      if (!userResult) {
        MENU_ITEMS.splice(2, 1);
      }

      // MENU_ITEMS.push(Master); // 1
      const masterResult = role.page.find(repo =>
        (repo.page === 'Appoinment' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Charactristics' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Category' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Subcategory' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'HomeRemedes' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Product' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'OurEvent' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Branch' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'CaseStudy' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'SuccessStories' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Successdisease' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Testimonial' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'purpose' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Team' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Achivement' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Banner' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'RoleRight' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'BusinessValueFactor' && (repo.isRead || repo.isWrite))
      );

      if (!masterResult) {
        MENU_ITEMS.splice(1, 1);
      }

      // dashboard 0

      const dashboardResult = role.page.find(repo =>
        (repo.page === 'New' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Accept' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'In-Process' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Delivered' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Cancelled' && (repo.isRead || repo.isWrite))
      );

      if (!dashboardResult) {
        MENU_ITEMS.splice(0, 1);
      }
    }
    return MENU_ITEMS;
  }
}
