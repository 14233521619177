import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { navItems } from '../../_nav';
import { MenuService } from '../../shared/services/menu.service';
// import {} from '../../../../src/assets/img/brand/logo.jpg'
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  // public navItems = navItems;

  public navItems :any;
  constructor(private router: Router,
    private  menuService: MenuService
    ) {}
    ngOnInit() {
      setTimeout(()=>{                       
        this.navItems =   this.menuService.setMenu();
      }, 10000);
    }
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
